import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";
import "slick-carousel";
import "../../node_modules/slick-carousel/slick/slick-theme.scss";
window.$ = window.jQuery = require('jquery');
window.isotope = require("isotope-layout/dist/isotope.pkgd");


gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);



// ----------------------------------------
// ON LOAD
// ----------------------------------------

(function () {
  $(window).on('load', function () {
    checkHeader();
    $('main').css('opacity', 1);
    $('aside').css('opacity', 0);
    checkLocationHash();


    // Social links
    if ($('#social-share')) {

      // window paramters
      let params = `status=no,location=no,toolbar=no,menubar=no,width=700,height=500,left=400,top=400`;

      // click event listeners
      document.getElementById('social-share').querySelectorAll('a').forEach((item)=>{
        const url = item.getAttribute('href');

        item.addEventListener('click',(e)=>{
          // callback
          e.preventDefault();
          open(url, 'Social Share', params);
        });
      });
    }
    
    // ________________________________
    // Casos - Mobile filter loader
    // ''''''''''''''''''''''''''''''''

    if ($('.casos-estudio')[0]) {

      let filterbox = $('#filterbox');
      let filterbox_loader = $('#filterbox-loader');
      let filterwrap = $('#filterwrap');

      filterbox_loader.on('click', function(){

          filterbox.slideToggle();
          filterwrap.toggleClass('off');

        if ($('.filters__link.active')[0]) {
          filterbox_loader.addClass('active');
        } else {
          filterbox_loader.removeClass('active');
        }
      });
    }

  });

  $(document).ready(function () {

    // WINDOW SCROLL

    $(window).scroll(function () {
      checkHeader();
    })


    if (screen.width < 501) {
      var slidenum = 1;
    } else if (screen.width < 1100) {
      var slidenum = 2;
    }
    else if (screen.width < 1600) {
      var slidenum = 3;
    } else {
      var slidenum = 3;
    }

    //SLIDER SINGLE CASO
    var $status = $(".pagingInfo");
    var $slickElement = $("#slider-reto");
    $slickElement.on(
      "init reInit afterChange",
      function (event, slick, currentSlide, nextSlide) {
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        var i = (currentSlide ? currentSlide : 0) + 1;
        $(".slidecount__current").text(i);
        $(".slidecount__total").text(slick.slideCount);
      }
    );
    $("#slider-reto").slick({
      slidesToShow: 1,
      infinite: true,
      autoplay: false,
      arrows: false,
      swipe: true,
    });
    $(".prev-arrow").on("click", function () {
      $("#slider-reto").slick("slickPrev");
    });
    $(".next-arrow").on("click", function () {
      $("#slider-reto").slick("slickNext");
    });
    //SLIDER SINGLE CASO FIN


    //SLIDER SERVICIOS
    const slider = $('#servicios-slider');

    function onSliderAfterChange(event, slick, currentSlide) {
      $(event.target).data('current-slide', currentSlide);
    }

    function onSliderWheel(e) {
      var deltaY = e.originalEvent.deltaY,
        $currentSlider = $(e.currentTarget),
        currentSlickIndex = $currentSlider.data('current-slide') || 0;

      if (
        // check when you scroll up
        (deltaY < 0 && currentSlickIndex == 0) ||
        // check when you scroll down
        (deltaY > 0 && currentSlickIndex == $currentSlider.data('slider-length') - 1)
      ) {
        return;
      }

      e.preventDefault();

      if (e.originalEvent.deltaY < 0) {
        $currentSlider.slick('slickPrev');
      } else {
        $currentSlider.slick('slickNext');
      }
    }

    slider.each(function (index, element) {
      var $element = $(element);
      // set the length of children in each loop
      // but the better way for performance is to set this data attribute on the div.slider in the markup
      $element.data('slider-length', $element.children().length);
    })
      .slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        fade: true,
        cssEase: 'linear',
      })
      .on('afterChange', onSliderAfterChange)
      .on('wheel', onSliderWheel);
    //SLIDER SERVICIOS FIN


    //FILE NAME FROM INPUT FILE
    $('input[type="file"]').change(function (e) {
      setTimeout(function () {
        if (!e.target.classList.contains('wpcf7-not-valid')) {
          var fileName = e.target.files[0].name;
          $('.file-uploader').attr('data-content',fileName);
        } 
      }, 100);
    });
    //FILE NAME FROM INPUT FILE END


    //MENU 
    $('.ham').on('click', function () {
      $('body').toggleClass('menu-active');

      $('.nav-inedit').toggleClass('menu-active');

    });

    $('.close').on('click', function () {
      $('.nav-inedit').toggleClass('menu-active');
      setTimeout(
        function () {
          $('body').toggleClass('menu-active');
        }, 200);

    });

    //WPML language selectors

    // FOOTER

    $('.wpml-ls-item-toggle, .wpml-ls-sub-menu').on('mouseover', function () {
      $('.wpml-ls-item-toggle').addClass('active');
    });
    $('.wpml-ls-item-toggle, .wpml-ls-sub-menu').on('mouseleave', function () {
      $('.wpml-ls-item-toggle').removeClass('active');
    });

    // HEADER

    $('#world').hover(()=>{
      $('#language-list').show()
    },()=>{
      $('#language-list').hide()
    })

    $('#language-list').hover(()=>{
      $('#language-list').show()
    },()=>{
      $('#language-list').hide()
    })

    $('#world').click(()=>{
      $('#language-list').toggle()
    })

    //MENU FIN


    //PASTILLA HOME BANNER
    $('.pastilla').on('click', () => {
      $('.testimonial').toggleClass('active');
    });
    //PASTILLA HOME BANNER FIN


    //SELECT POSTS
    $(".select-posts").on("click", function () {
      var val = $(this).data('value');
      console.log(val);
      $(".noticia").not("." + val).hide();
      $("." + val).show();
    });
    //SELECT POSTS FIN

    
    //HOM ENUMBERS ANIMATION
    function isVisible(ele) {
      const { top, bottom } = ele.getBoundingClientRect();
      const vHeight = (window.innerHeight || document.documentElement.clientHeight);

      return (
        (top > 0 || bottom > 0) &&
        top < vHeight
      );
    }
    var numbDiv = document.getElementById('counter');
    var scrolled = false;
    
      $(window).on("scroll", function () {
        if($('body').hasClass('page-template-front-page')){
        if (isVisible(numbDiv) && !scrolled) {
          $('.number-count').each(function () {
            var $this = $(this);
            jQuery({ Counter: 0 }).animate({ Counter: $this.text() }, {
              duration: 2000,
              easing: 'swing',
              step: function () {
                $this.text(Math.ceil(this.Counter));
              }
            });
            scrolled = true;
          });
        }
      }
      });
    
    // -----------------------------
    // ISOTOPE casos
    // -----------------------------

    if ($('.post-type-archive-casos')[0]) {
    

      // define mobile loader

      let filterbox_loader = $('#filterbox-loader')

      // filter initialization

      let filters = { 
        'serveis': [], 
        'sectors': []
      };
      let addedFilters = '';


      // process url data if there is 

      const params = getAllUrlParams();

      console.log('debug isotope',params);

      if (params.filter) {

        addedFilters = params.filter;
        
        let filter_split = params.filter.split(",");

        filter_split.forEach(function(item) {

          let item_split = item.split(".");

          let servei = '.' + item_split[1];
          concatValues( servei, 'serveis' );
          $('[data-filter="'+ servei +'"]').addClass('active');

          if (item_split.length > 2) {
            let sector = '.' + item.split(".")[2];
            concatValues( sector, 'sectors' );
            $('[data-filter="'+ sector +'"]').addClass('active');
          }

        })  

        filterbox_loader.addClass('active');

      }

      // create isotope

      var $grid = $(".casos__items").isotope({
        itemSelector: '.caso',
        transitionDuration: 500,
        layoutMode: 'fitRows',
        filter: addedFilters
      });

      // click event listeners

      $('.filter-menu').on('click', 'li', function (event) {

        var $target = $(event.currentTarget);
        $target.toggleClass('active');

        var filter_data = $target.attr('data-filter');
        var type_data = $target.attr('data-type');

        if ( $target.hasClass('active') ) {
          concatValues( filter_data, type_data );
        } else {
          removeFrom( filter_data, type_data );
        }

        addedFilters = addFilters(filters.serveis,filters.sectors);

        if (addedFilters) {
          filterbox_loader.addClass('active');
          setBrowserURL(window.location.href.split('?')[0] + '?filter=' + addedFilters);
        } else {
          filterbox_loader.removeClass('active');
          setBrowserURL(window.location.href.split('?')[0]);
        }

        // console.log(addedFilters);

        $grid.isotope({ filter: addedFilters });

      });

      $('.caso-permalinks').on('click', function (event) {

        if (addedFilters) {

          var $target = $( event.currentTarget );
          var url = $target.attr('href');
          url += '?filter=' + addedFilters;
          $target.attr('href',url);

        }
      });

      // aux functions

      function addFilters(filters1, filters2) {

        let added = '';

        if (filters1.length) {
          filters1.forEach(function(class1) {
            if (filters2.length) {

              // filters1 + filters2
              filters2.forEach(function(class2) {
                added += class1 + class2 + ',';
              });

            } else {

              // only filters1
              added += class1 + ',';

            }
          });

          return added.slice(0, -1);

        } else {

          if (filters2.length) {            
            filters2.forEach(function(class2) {

              // only filters2
              added += class2 + ',';

            });

          } else {

            // no filters at all
            return ''

          }
        }

        return added.slice(0, -1);
      }

      function concatValues(filter, type) {

        let index = filters[type].indexOf( filter );

        if ( index == -1 ) {
          filters[type].push( filter );
        }
      }

      function removeFrom(filter, type) {

        let index = filters[type].indexOf( filter );

        if ( index != -1 ) {
          filters[type].splice( index, 1 );
        }
      }

      function setBrowserURL(url) {
        if (history.pushState) {
          window.history.pushState({}, document.title, url);
        } else {
          window.history.replaceState({}, document.title, url);
        }
      }

      // // reset button
      //
      // $('.reset').on('click', function () {
      //   filters = [];
      //   $('.post-type-archive-casos .casos__items').isotope({
      //     filter: '*'
      //   });
      //   $('.filter-menu li').removeClass('active');
      //   });
    }

    // Single CASOS
    // put parameters in back button link

    if ($('.single-casos')[0]) {
  
      const params = getAllUrlParams();

      if (params.filter) {
        const btnVolver = document.getElementById('btn-volver');
        let url = btnVolver.getAttribute('href');
  
        url += '?filter=' + params.filter;
        btnVolver.setAttribute('href', url);
      }

    }
    

    // -----------------------------
    // ISOTOPE POSTS
    // -----------------------------

    if ($('.page-template-archive')[0]) {

      let $gridPosts = $(".posts__items").isotope({
        itemSelector: '.post',
        transitionDuration: 500,
        layoutMode: 'fitRows',
      });
     
      function concatValuesPost(filter) {
        if ( filtersPost.indexOf( filter ) == -1 ) {
          filtersPost.push( filter );
        }
      }
      function removeFromPost( filter) {
        var index = filtersPost.indexOf( filter);
        if ( index != -1 ) {
          filtersPost.splice( index, 1 );
        }
      }
      
      var filtersPost = [];
      
      $('.posts__filtros').on('click', 'li', function (event) {
        var $target = $( event.currentTarget );
        $target.toggleClass('active');
        var isChecked = $target.hasClass('active');
        var filter = $target.attr('data-filter');
        if ( isChecked ) {
          concatValuesPost( filter );
        } else {
          removeFromPost( filter );
        }
        $gridPosts.isotope({ filter: filtersPost.join(',') });
      });

    }


  });


// ----------------------------------------
// location hash smooth scroll
// ----------------------------------------

let the_hash = location.hash;

function checkLocationHash() {
    if (the_hash) {
        smoothScrollTo($(the_hash));
    }
}

function smoothScrollTo(element) {
    scroll({
      top: element.offset().top,
      behavior: "smooth"
    });
}

})();

function getAllUrlParams(url) { // source: https://www.codegrepper.com/profile/mysterious-mosquito-ntw1hk6d203w

  // get query string from url (optional) or window
  var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {

    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {

        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string'){
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}


// ----------------------------------------
// AUX FUNCTIONS
// ----------------------------------------

function checkHeader() {
  let scrolled = $(window).scrollTop();
  let header = $("header");
  
  if ( scrolled > 100) {
    header.addClass('scroll');
  }
  if (scrolled < 100) {
    header.removeClass('scroll');
  }
}



